@keyframes slideInFromTop {
    0% {
    transform: translateY(-100%);
    opacity: 0;
    }
    100% {
    transform: translateY(0);
    opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
    transform: translateX(-100%);
    opacity: 0;
    }
    100% {
    transform: translateX(0);
    opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
    transform: translateX(100%);
    opacity: 0;
    }
    100% {
    transform: translateX(0);
    opacity: 1;
    }
}

@keyframes appear {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}

@keyframes spin {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
}

.down__animation {  
    animation: 1s ease-out 0s 1 slideInFromTop;
}

.right__animation {  
    animation: 2s ease-out 0s 1 slideInFromLeft;
}

.left__animation {
    animation: 2s ease-out 0s 1 slideInFromRight;
}

.appear__animation {
    animation: 2s ease-out 0s 1 appear;
}

.spin__animation {
    animation: 1.5s ease-out 0s 1 spin;
}