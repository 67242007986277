:root {
    color-scheme: dark;
}

.body {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 75vh;
    font-family: 'Roboto', sans-serif;
    margin-left: 20px;
    margin-right: 20px;
}

h1 {
    color: white;
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 75px;
    font-weight: 700;
    text-align: center;
}

.regular-title {
    margin-top: 100px;
    margin-bottom: 40px;
}

.title-logo {
    width: 100px;
    display: block;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    margin-bottom: 10px;
}

.typewriter {
    color: lightgray;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
}

.try-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    margin-bottom: 50px;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: #702e67;
    color: white;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
    text-decoration: none;
    transition: 0.2s;
}

.try-button:hover {
    color: lightgray;
    background-color: #5c2459;
    text-decoration: none;
}

a {
    color: #007BFF;
    text-decoration: none;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

p {
    color: lightgray;
}

li {
    color: lightgray;
}

ul {
    color: lightgray;
}

.nav {
    display: inline-block;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #702e67;
    opacity: 0.95;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.nav-element {
    display: inline-block;
    margin: 0 10px;
    color: white;
}

.nav-right {
    float: right;
    margin: 0 10px;
    color: white;
}

.nav-logo {
    top: 4px;
    width: 50px;
    position: absolute;
}

.nav-element:hover {
    color: #ffd4ff;
    text-decoration: none;
}

.nav-right:hover {
    color: #ffd4ff;
    text-decoration: none;
}

.nav-element:active {
    transform: scale(1.1);
}

footer {
    margin-top: 50px;
    text-align: center;
}

@media (max-width: 1000px) {
    .splitHome {
        flex-direction: column;
    }

    .demoImg {
        width: calc(100% - 15px);
    }
}
