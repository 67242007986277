button {
    padding: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    transition: 0.1s;
}

#startButton {
    background-color: green;
}

#startButton:hover {
    background-color: #90EE90;
}

#startButton:active {
    transform: scale(0.95);
}

#stopButton {
    background-color: red;
}

#stopButton:hover {
    background-color: pink;
}

#stopButton:active {
    transform: scale(0.95);
}

#resetButton {
    background-color: blue;
}

#resetButton:hover {
    background-color: #87CEFA;
}

#resetButton:active {
    transform: scale(0.95);
}

.split {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.settings {
    margin: 0;
    padding: 0;
}

.presentation {
    background-color: #27273c;
    border: 1px solid white;
    border-radius: 10px;
    width: calc(100% - 110px);
    min-height: calc(100vh - 170px);
    overflow: hidden;
    position: relative;
}

.transcript {
    font-size: 15px;
    color: white;
    background-color: black;
    padding: 5px;
    opacity: 0.8;
    bottom: 0;
    position: absolute;
}

h3 {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
}

.slide {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.aiImg {
    width: 80%;
    height: auto;
    max-width: 500px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: 0 0 5px lightgray;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#summary {
    margin-top: 15px;
    width: calc(100% - 30px);
}

@media (max-width: 650px) {
    .split {
        flex-direction: column;
    }
  
    .presentation {
        margin-top: 20px;
        width: 100%;
        min-height: calc(100vh - 315px);
    }

    #summary {
        width: 100%;
        width: calc(100% - 7px);
    }
  }
  